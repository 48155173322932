import React from "react";
import { useEffect } from "react";
import { MDBContainer} from "mdb-react-ui-kit";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import "../Styles/contact.css"; // Importar o CSS atualizado
import logo from "../Assets/Images/logoprimepisos.png";
import headerBg from "../Assets/Images/Produtos/header.png"; // Imagem de fundo do header

const Contact = () => {

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  return (
    <MDBContainer fluid className="contact-page">
      {/* Header */}
      <div className="contact-header" style={{ backgroundImage: `url(${headerBg})` }}>
        <img src={logo} alt="Prime Pisos Logo" className="prime-logo" />
      </div>

      {/* Contato Section */}
      <MDBContainer className="contact-section">
        <div className="form-wrapper">
          {/* Título e Descrição */}
          <div className="contact-title">
            <h3>Contato</h3>
            <p className="contact-description">
              Preencha o formulário para entrar em contato, ou se preferir, utilize um de nossos canais.
            </p>
          </div>

          {/* Conteúdo da sessão de contato */}
          <div className="contact-content">
            {/* Esquerda: Informações de Contato */}
            <div className="contact-info">
              <h4>Atendimento</h4>
              <p>
                <FaPhoneAlt /> +55 11 2707-3738
              </p>
              <p>
                <IoLogoWhatsapp /> 11 9 9389-1149
              </p>
              <h4>Envie um e-mail</h4>
              <p>
                <FaEnvelope /> comercial@primepisoselevados.com.br
              </p>
              <h4>Onde Estamos</h4>
              <p>
                <FaMapMarkerAlt /> Rua Dr. Edgard Magalhães Noronha, nº 355 - Vila Nova York, São Paulo - SP, 03480-000
              </p>

              {/* Redes Sociais */}
              <div className="social-icons">
                <a href="https://www.instagram.com/" className="social-icon">
                  <FaInstagram />
                </a>
                <a href="https://www.twitter.com/" className="social-icon">
                  <FaTwitter />
                </a>
                <a href="https://www.facebook.com/" className="social-icon">
                  <FaFacebook />
                </a>
              </div>
            </div>

            {/* Direita: Formulário */}
            <div className="contact-form">
              <input type="text" placeholder="Nome" className="input-field" />
              <input type="email" placeholder="E-mail" className="input-field" />
              <input type="text" placeholder="Telefone" className="input-field" />
              <input type="text" placeholder="Empresa" className="input-field" />
              <textarea placeholder="Mensagem" className="input-field textarea-field"></textarea>
              <button className="submit-button">Enviar</button>
            </div>
          </div>
        </div>
      </MDBContainer>

      {/* Google Maps */}
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14626.949382023788!2d-46.5089081!3d-23.577875!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce67626d9a31f3%3A0xe8c2cced91aa824e!2sPrime%20Pisos%20Elevados!5e0!3m2!1spt-BR!2sbr!4v1726000122784!5m2!1spt-BR!2sbr"
          className="google-maps"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Localização do Prime Pisos Elevados no Google Maps"
        ></iframe>
      </div>
    </MDBContainer>
  );
};

export default Contact;
