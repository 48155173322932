import React from "react";
import { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import {
  FaHammer,
  FaToolbox,
  FaProjectDiagram,
  FaShieldAlt,
  FaArrowDown,
} from "react-icons/fa";
import "../Styles/index.css";
import tituloImagem from "../Assets/Images/Destaques/destaques.png";
import sobre1 from "../Assets/Images/Sobre/sobre1.png";
import sobre2 from "../Assets/Images/Sobre/sobre2.png";
import sobre3 from "../Assets/Images/Sobre/sobre3.png";
import banner1 from "../Assets/Images/BannerIndex/banner1.png";
import banner2 from "../Assets/Images/BannerIndex/banner2.png";
import banner3 from "../Assets/Images/BannerIndex/banner3.png";
import logo from "../Assets/Images/logoprimepisos.png";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // Altere para o intervalo que preferir
  fade: true, // Habilita transição suave
};

export default function Index() {

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  return (
    <>
      <div className="home-page">
        {/* Seção Header */}
        <div className="header-section">
          {/* Carrossel */}
          <div className="slider-wrapper">
            <Slider {...settings}>
              <div className="slide">
                <img src={banner1} alt="Banner 1" className="slide-image" />
              </div>
              <div className="slide">
                <img src={banner2} alt="Banner 2" className="slide-image" />
              </div>
              <div className="slide">
                <img src={banner3} alt="Banner 3" className="slide-image" />
              </div>
            </Slider>
          </div>

          {/* Conteúdo do Header */}
          <div className="header-elements">
            <MDBContainer fluid className="header-container">
              <div className="header-text-bg">
                <img
                  src={logo}
                  alt="Prime Pisos Elevados"
                  className="header-logo animate__animated animate__fadeInDown"
                />
                <p className="header-text animate__animated animate__fadeInLeft">
                  Na Prime Pisos Elevados, oferecemos soluções inovadoras e de alta qualidade em pisos elevados para ambientes comerciais e industriais, focando em eficiência e segurança. Nossos produtos são rigorosamente testados para garantir máxima resistência.
                </p>
              </div>
              <div className="header-buttons animate__animated animate__fadeInUp">
                <MDBBtn color="light" outline className="header-button simule-button">
                  <FaArrowDown className="button-icon" />
                  SIMULE UMA COTAÇÃO
                </MDBBtn>
                <MDBBtn color="light" className="header-button saiba-mais-button">
                  Saiba Mais
                </MDBBtn>
              </div>
            </MDBContainer>
          </div>
        </div>

        <MDBContainer className="home-container">
          {/* Seção de Sobre */}
          <MDBRow className="sobre-section">
            <MDBCol lg="6" className="sobre-img-col">
              <img
                src={sobre1}
                alt="Sobre 1"
                className="sobre-img animate__animated animate__fadeInLeft"
              />
            </MDBCol>
            <MDBCol lg="6" className="sobre-content-col">
              <h2 className="sobre-title">
                <FaShieldAlt className="title-icon" /> Tecnologia e Inovação
              </h2>
              <p className="sobre-text">
                Na Prime Pisos Elevados, utilizamos a mais avançada tecnologia
                para garantir que os seus projetos de pisos elevados tenham
                durabilidade e alta performance, oferecendo soluções adequadas
                às necessidades do seu espaço.
              </p>
              <MDBBtn color="dark" className="sobre-button">
                Ver mais informações
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBRow className="sobre-section reverse">
            <MDBCol lg="6" className="sobre-img-col">
              <img
                src={sobre2}
                alt="Sobre 2"
                className="sobre-img animate__animated animate__fadeInRight"
              />
            </MDBCol>
            <MDBCol lg="6" className="sobre-content-col">
              <h2 className="sobre-title">
                <FaProjectDiagram className="title-icon" /> Soluções Personalizadas
              </h2>
              <p className="sobre-text">
                Cada projeto tem suas próprias particularidades. Desenvolvemos
                pisos elevados sob medida, com foco na eficiência, design e
                durabilidade, atendendo a todas as especificações técnicas de
                nossos clientes.
              </p>
              <MDBBtn color="dark" className="sobre-button">
                Ver mais informações
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBRow className="sobre-section">
            <MDBCol lg="6" className="sobre-img-col">
              <img
                src={sobre3}
                alt="Sobre 3"
                className="sobre-img animate__animated animate__fadeInLeft"
              />
            </MDBCol>
            <MDBCol lg="6" className="sobre-content-col">
              <h2 className="sobre-title">
                <FaShieldAlt className="title-icon" /> Compromisso com a Qualidade
              </h2>
              <p className="sobre-text">
                Nossos pisos elevados são fabricados com os mais altos padrões
                de qualidade, proporcionando segurança e durabilidade
                incomparáveis. Trabalhamos com os melhores materiais e processos
                do mercado.
              </p>
              <MDBBtn color="dark" className="sobre-button">
                Ver mais informações
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          {/* Seção de Destaques */}
          <MDBRow className="destaques-section">
            <MDBCol size={12} className="destaques-titulo">
              <img
                src={tituloImagem}
                alt="Destaques"
                className="destaques-imagem"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="destaques-cards">
            <MDBCol size={2} className="card-destaque">
              <FaHammer className="card-icon animate-icon" />
              <p className="card-title-bold">Durabilidade e Resistência</p>
            </MDBCol>
            <MDBCol size={2} className="card-destaque">
              <FaToolbox className="card-icon animate-icon" />
              <p className="card-title-bold">Manutenção Preventiva</p>
            </MDBCol>
            <MDBCol size={2} className="card-destaque">
              <FaProjectDiagram className="card-icon animate-icon" />
              <p className="card-title-bold">Soluções Sob Medida</p>
            </MDBCol>
            <MDBCol size={2} className="card-destaque">
              <FaShieldAlt className="card-icon animate-icon" />
              <p className="card-title-bold">Qualidade Garantida</p>
            </MDBCol>
          </MDBRow>

          {/* Seção de Vídeo */}
          <MDBRow className="video-section d-flex justify-content-center">
            <MDBCol
              lg="8"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="video-container">
                <iframe
                  className="iframePrime"
                  src="https://www.youtube.com/embed/0BCdxFqE4qM?si=5oD3rKkdthRoHYwV"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* <Depoimentos /> */}
      </div>
    </>
  );
}
