import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { FaRulerVertical, FaCogs, FaWarehouse } from "react-icons/fa";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import "../Styles/simulation.css";
import "../Styles/contact.css";
import { api } from "../controllers/apiController";

const Simulacao = () => {

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  // Estados para a simulação de preços
  const [altura, setAltura] = useState(10);
  const [revestimento, setRevestimento] = useState('Sem Revestimento');
  const [metrosQuadrados, setMetrosQuadrados] = useState(0);
  const [precoFinal, setPrecoFinal] = useState(null);

  // Estado para coleta de leads
  const [lead, setLead] = useState({ nome: '', email: '', telefone: '', empresa: '' });

  // Preços e opções conforme os dados fornecidos
  const precos = {
    10: { 'Sem Revestimento': 209, 'Laminado Liso': 307, 'Laminado Liso Dissipativo': 362 },
    15: { 'Sem Revestimento': 210, 'Laminado Liso': 308, 'Laminado Liso Dissipativo': 363 },
    20: { 'Sem Revestimento': 211, 'Laminado Liso': 310, 'Laminado Liso Dissipativo': 364 },
    25: { 'Sem Revestimento': 212.50, 'Laminado Liso': 311, 'Laminado Liso Dissipativo': 365.50 },
    30: { 'Sem Revestimento': 214.50, 'Laminado Liso': 312, 'Laminado Liso Dissipativo': 367 },
    35: { 'Sem Revestimento': 215, 'Laminado Liso': 313.50, 'Laminado Liso Dissipativo': 368 },
    40: { 'Sem Revestimento': 254, 'Laminado Liso': 353, 'Laminado Liso Dissipativo': 409 },
    45: { 'Sem Revestimento': 255.50, 'Laminado Liso': 354.50, 'Laminado Liso Dissipativo': 410.50 },
    50: { 'Sem Revestimento': 257, 'Laminado Liso': 355.50, 'Laminado Liso Dissipativo': 411.50 },
    55: { 'Sem Revestimento': 258.50, 'Laminado Liso': 357, 'Laminado Liso Dissipativo': 413 },
    60: { 'Sem Revestimento': 260, 'Laminado Liso': 358.50, 'Laminado Liso Dissipativo': 414.50 },
    65: { 'Sem Revestimento': 261.50, 'Laminado Liso': 360.50, 'Laminado Liso Dissipativo': 416 },
    70: { 'Sem Revestimento': 263, 'Laminado Liso': 362, 'Laminado Liso Dissipativo': 418 },
    75: { 'Sem Revestimento': 264.50, 'Laminado Liso': 363.50, 'Laminado Liso Dissipativo': 419.50 },
    80: { 'Sem Revestimento': 272.50, 'Laminado Liso': 363.50, 'Laminado Liso Dissipativo': 427 },
    85: { 'Sem Revestimento': 274, 'Laminado Liso': 365, 'Laminado Liso Dissipativo': 428.50 },
    90: { 'Sem Revestimento': 275.50, 'Laminado Liso': 367, 'Laminado Liso Dissipativo': 430 },
    95: { 'Sem Revestimento': 278, 'Laminado Liso': 369, 'Laminado Liso Dissipativo': 433 },
    100: { 'Sem Revestimento': 279.50, 'Laminado Liso': 371, 'Laminado Liso Dissipativo': 435 },
    105: { 'Sem Revestimento': 280.50, 'Laminado Liso': 373, 'Laminado Liso Dissipativo': 437 },
    110: { 'Sem Revestimento': 281.50, 'Laminado Liso': 375, 'Laminado Liso Dissipativo': 438 },
    115: { 'Sem Revestimento': 282.50, 'Laminado Liso': 379.50, 'Laminado Liso Dissipativo': 439 },
    120: { 'Sem Revestimento': 285, 'Laminado Liso': 384, 'Laminado Liso Dissipativo': 439.50 },
  };

  // Fechamento lateral
  const fechamentoLateral = {
    30: 200, // Até 30cm
  };

  // Rampa de Acesso
  const rampaAcesso = {
    30: 500, // Até 30cm
  };

  // Instalação sem longarina
  const instalacaoSemLongarina = {
    diaria: 1200, // Diária de Instalação até 50m²
    montagem: 18, // Montagem acima de 150m²
  };

  // Instalação com longarina
  const instalacaoComLongarina = {
    diaria: 1500, // Diária de Instalação até 50m²
    montagem: 20, // Montagem acima de 150m²
  };

  // Frete
  const frete = {
    HR: 800, // Leva até 50m²
    truck: 2500, // Leva até 360m²
  };


  // Função para validar o e-mail
  const validarEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Função para calcular o preço final e enviar os dados da simulação
  const calcularPreco = async () => {
    if (!lead.nome || !lead.email) {
      alert('Por favor, preencha o nome e o e-mail para realizar a simulação.');
      return;
    }

    if (!validarEmail(lead.email)) {
      alert('Por favor, insira um e-mail válido.');
      return;
    }

    const precoBase = precos[altura][revestimento];
    const precoTotal = precoBase * metrosQuadrados;
    setPrecoFinal(precoTotal);

    const response = await fetch(`${api}/leads/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...lead,
        altura,
        revestimento,
        metros_quadrados: metrosQuadrados,
        preco_final: precoTotal,
        tipo_contato: 'simulacao',
      }),
    });
    const data = await response.json();
    console.log(data.message);
    alert('Simulação realizada com sucesso e lead enviado!');
  };

  // Função para enviar os dados do formulário de contato
  const enviarContato = async () => {
    if (!lead.nome || !lead.email) {
      alert('Por favor, preencha o nome e o e-mail para entrar em contato.');
      return;
    }

    if (!validarEmail(lead.email)) {
      alert('Por favor, insira um e-mail válido.');
      return;
    }

    const response = await fetch(`${api}/leads/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...lead,
        tipo_contato: 'contato',
      }),
    });
    const data = await response.json();
    console.log(data.message);
    alert('Formulário de contato enviado com sucesso!');
  };

  return (
    <>
      <MDBContainer fluid className="simulacao-page p-0">
        <MDBRow className="simulacao-content">
          {/* Coluna da Esquerda: Detalhes e Legendas */}
          <MDBCol md="6" className="simulacao-text-wrapper">
            <h2 className="section-title">Simulação de preços</h2>
            <p className="simulacao-text">
              Simule agora o custo do seu projeto, escolhendo a altura do piso, o tipo de revestimento e a área em metros quadrados. Preencha seus dados e receba o cálculo imediato!
            </p>

            {/* Legenda aparece após a simulação */}
            {precoFinal !== null && (
              <div className="legenda">
                <h4>Legenda dos Custos:</h4>
                <ul>
                  <li><strong>Sem Revestimento:</strong> Piso sem acabamento extra.</li>
                  <li><strong>Laminado Liso:</strong> Revestimento liso para maior durabilidade.</li>
                  <li><strong>Laminado Liso Dissipativo:</strong> Indicado para ambientes que requerem maior segurança eletrostática.</li>
                </ul>
              </div>
            )}
          </MDBCol>

          {/* Coluna da Direita: Formulário de Simulação */}
          <MDBCol md="6" className="simulacao-box-wrapper">
            <MDBCard className="simulacao-card p-3 shadow-5">
              <MDBCardBody>

                <div className="simulacao-form">
                  <MDBRow>
                    <MDBCol md="12" className="mb-3">
                      <label className="form-label"><FaRulerVertical /> Altura do Piso (cm): </label>
                      <select
                        className="form-select"
                        value={altura}
                        onChange={(e) => setAltura(Number(e.target.value))}
                      >
                        {Object.keys(precos).map((altura) => (
                          <option key={altura} value={altura}>{altura} cm</option>
                        ))}
                      </select>
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                      <label className="form-label"><FaCogs /> Tipo de Revestimento: </label>
                      <select
                        className="form-select"
                        value={revestimento}
                        onChange={(e) => setRevestimento(e.target.value)}
                      >
                        <option value="Sem Revestimento">Sem Revestimento</option>
                        <option value="Laminado Liso">Laminado Liso</option>
                        <option value="Laminado Liso Dissipativo">Laminado Liso Dissipativo</option>
                      </select>
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                      <label className="form-label"><FaWarehouse /> Área em Metros Quadrados: </label>
                      <input
                        type="number"
                        className="form-control"
                        value={metrosQuadrados}
                        onChange={(e) => setMetrosQuadrados(Number(e.target.value))}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Nome"
                        value={lead.nome}
                        onChange={(e) => setLead({ ...lead, nome: e.target.value })}
                      />
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="E-mail"
                        value={lead.email}
                        onChange={(e) => setLead({ ...lead, email: e.target.value })}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn className="mt-4 send-sim-button" color="primary" block onClick={calcularPreco}>Calcular Valor</MDBBtn>
                  {precoFinal !== null && (
                    <div className="mt-4">
                      <h3 className='final-value' >Preço Total: R$ {precoFinal.toFixed(2)}</h3>
                    </div>
                  )}
                </div>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Seção de Contato */}
      <MDBContainer fluid className="contact-page pt-5">
        <MDBContainer className="contact-section">
          <div className="form-wrapper">
            <div className="contact-title">
              <h3>Contato</h3>
              <p className="contact-description">
                Preencha o formulário para entrar em contato, ou se preferir, utilize um de nossos canais.
              </p>
            </div>

            <div className="contact-content">
              <div className="contact-info">
                <h4>Atendimento</h4>
                <p>
                  <a style={{ color: '#f5f5f5' }} href="tel:1127073738">
                    <FaPhoneAlt /> +55 11 2707-3738
                  </a>
                </p>
                <p>
                  <a style={{ color: '#f5f5f5' }} href="https://api.whatsapp.com/send?phone=11993891149">
                    <IoLogoWhatsapp color="white" /> (11) 9 9389-1149
                  </a>
                </p>
                <p>
                  <a style={{ color: '#f5f5f5' }} href="https://api.whatsapp.com/send?phone=11994464218">
                    <IoLogoWhatsapp color="white" />(11) 9 9446-4218
                  </a>
                </p>

                <h4>Envie um e-mail</h4>
                <p>
                  <a style={{ color: '#f5f5f5' }} href='mailto:atendimento@primepisoselevados.com.br'>
                    <span style={{display: 'inline-block'}}><FaEnvelope /></span> <span style={{display: 'inline-flex', width:'80%'}}>atendimento@primepisoselevados.com.br</span>
                  </a>
                </p>
                <p>
                  <a style={{ color: '#f5f5f5' }} href='mailto:primepisoselevados@primepisoselevados.com.br'>
                    <span style={{display: 'inline-block'}}><FaEnvelope /></span> <span style={{display: 'inline-flex', width:'80%'}}>primepisoselevados@primepisoselevados.com.br</span>
                  </a>
                </p>
                <h4>Onde Estamos</h4>
                <p>
                  <FaMapMarkerAlt /> Rua Dr. Edgard Magalhães Noronha, nº 355 - Vila Nova York, São Paulo - SP, 03480-000
                </p>

                {/* Redes Sociais */}
                <div className="social-icons">
                  <a href="https://www.instagram.com/" className="social-icon">
                    <FaInstagram />
                  </a>
                  <a href="https://www.twitter.com/" className="social-icon">
                    <FaTwitter />
                  </a>
                  <a href="https://www.facebook.com/" className="social-icon">
                    <FaFacebook />
                  </a>
                </div>
              </div>

              <div className="contact-form">
                <input type="text" placeholder="Nome" className="input-field" />
                <input type="email" placeholder="E-mail" className="input-field" />
                <input type="text" placeholder="Telefone" className="input-field" />
                <input type="text" placeholder="Empresa" className="input-field" />
                <textarea placeholder="Mensagem" className="input-field textarea-field"></textarea>
                <button className="submit-button" onClick={enviarContato}>Enviar</button>
              </div>
            </div>
          </div>
        </MDBContainer>

        {/* Google Maps */}
        <div className="map-section">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14626.949382023788!2d-46.5089081!3d-23.577875!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce67626d9a31f3%3A0xe8c2cced91aa824e!2sPrime%20Pisos%20Elevados!5e0!3m2!1spt-BR!2sbr!4v1726000122784!5m2!1spt-BR!2sbr"
            className="google-maps"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Localização do Prime Pisos Elevados no Google Maps"
          ></iframe>
        </div>
      </MDBContainer>
    </>
  );
};

export default Simulacao;
