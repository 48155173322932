import React from "react";
import { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "../Styles/about.css"; // Importar o CSS atualizado
import logo from "../Assets/Images/logoprimepisos.png";
import produto1 from "../Assets/Images/Sobre/produto1.png"; // Caminho da imagem

// Importar ícones do react-icons
import { FaLayerGroup, FaTools, FaProjectDiagram } from "react-icons/fa";

const About = () => {

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  return (
    <MDBContainer fluid className="sobre-page">
      {/* Logo do Prime Pisos */}
      <MDBRow className="sobre-logo-section">
        <MDBCol md="12" className="text-center">
          <img src={logo} alt="Prime Pisos Logo" className="sobre-prime-logo" />
        </MDBCol>
      </MDBRow>

      <MDBRow className="sobre-content">
        <MDBCol lg={5} className="sobre-image-section">
          {/* <img src={produto1} alt="Produto 1" className="sobre-product-image" /> */}
        </MDBCol>
        <MDBCol lg={5} className="sobre-text-section">
          <div className="sobre-text-wrapper">
            <div className="sobre-title-overlay">
              <h1 className="sobre-titulo">Sobre Nós</h1>
            </div>
            <p className="sobre-descricao">
              Fundada em meados dos anos 90, a Prime Pisos Elevados se destaca como referência no mercado de pisos elevados. Iniciamos nossa trajetória como ferramentaria dedicada à indústria automobilística, e ao longo dos anos expandimos nossa atuação para oferecer soluções completas no segmento de pisos elevados.
            </p>
            <p className="sobre-descricao">
              Nossa missão é fornecer aos clientes soluções de pisos elevados de alta qualidade, utilizando tecnologia avançada e garantindo durabilidade e resistência. Valorizamos a inovação e a excelência, buscando sempre superar as expectativas dos nossos clientes.
            </p>
          </div>
        </MDBCol>
      </MDBRow>

      {/* Sessão de Cards */}
      <MDBRow className="sobre-card-section">
        <MDBCol md="3" className="p-0">
          <div className="sobre-card-wrapper">
            <div className="sobre-card-overlay">
              <span className="sobre-card-title">Pisos Elevados</span>
            </div>
            {/* Substituir a imagem pelo ícone */}
            <div className="sobre-card-icon pt-4">
              <FaLayerGroup size={160} />
            </div>
            <p className="sobre-card-description">
              Oferecemos pisos elevados em aço carbono, projetados para oferecer durabilidade e resistência excepcionais. Ideais para ambientes corporativos e comerciais que necessitam de infraestrutura flexível e moderna.
            </p>
          </div>
        </MDBCol>

        <MDBCol md="3" className="p-0">
          <div className="sobre-card-wrapper">
            <div className="sobre-card-overlay">
              <span className="sobre-card-title">Manutenção Preventiva</span>
            </div>
            {/* Substituir a imagem pelo ícone */}
            <div className="sobre-card-icon pt-4">
              <FaTools size={160} />
            </div>
            <p className="sobre-card-description">
              Nossos serviços de manutenção preventiva garantem a longevidade dos pisos elevados, prevenindo desgastes e garantindo o desempenho ideal ao longo do tempo.
            </p>
          </div>
        </MDBCol>

        <MDBCol md="3" className="p-0">
          <div className="sobre-card-wrapper">
            <div className="sobre-card-overlay">
              <span className="sobre-card-title">Projetos Personalizados</span>
            </div>
            {/* Substituir a imagem pelo ícone */}
            <div className="sobre-card-icon pt-4">
              <FaProjectDiagram size={160} />
            </div>
            <p className="sobre-card-description">
              Desenvolvemos projetos sob medida, adequados às necessidades específicas de cada cliente, garantindo soluções eficientes e personalizadas para qualquer tipo de ambiente.
            </p>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default About;
