import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import "../Styles/product.css"; // Importar o CSS atualizado
import logo from "../Assets/Images/logoprimepisos.png"; // Logo da Prime Pisos
import cardFrame from "../Assets/Images/Produtos/cardFrame.png"; // Fundo do card
import galeria01 from "../Assets/Images/Produtos/Galeria/galeria01.png"; // Imagem da galeria
import galeria02 from "../Assets/Images/Produtos/Galeria/galeria02.png"; // Imagem da galeria
import galeria03 from "../Assets/Images/Produtos/Galeria/galeria03.png"; // Imagem da galeria
import galeria04 from "../Assets/Images/Produtos/Galeria/galeria04.png"; // Imagem da galeria
import { api } from "../controllers/apiController";

const Products = () => {
  const [products, setProducts] = useState([]); // Estado inicializado como array vazio
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const navigate = useNavigate();

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez

  
  // Fetch de produtos da API
  useEffect(() => {
    axios
      .get(`${api}/produtos/todos`) // Certifique-se de que esta URL está correta
      .then((response) => {
        const fetchedProducts = response.data.produtos || [];
        setProducts(fetchedProducts); // Armazena os produtos no estado
        setLoading(false); // Desativa o estado de carregamento
      })
      .catch((error) => {
        console.error("Erro ao buscar os produtos:", error);
        setLoading(false); // Desativa o estado de carregamento em caso de erro
      });
  }, []);

  // Função para navegar para a página do produto
  const goToProduct = (nomeProduto) => {
    const slug = nomeProduto.replace(/\s+/g, "_"); // Transformar o nome do produto em um slug
    navigate(`/produto/${slug}`);
  };

  if (loading) {
    return <div>Carregando...</div>; // Renderiza uma mensagem de carregamento enquanto os produtos estão sendo buscados
  }

  return (
    <MDBContainer fluid className="product-page p-0">
      {/* Banner Section */}
      <div className="products-banner-section">
        <img src={logo} alt="Prime Pisos Logo" className="logo" />
      </div>

      {/* Product Section - Catálogo */}
      <MDBContainer className="product-section">
        <div className="text-wrapper">
          <h5 className="subtitulo">Catálogo</h5>
          <h2 className="section-title">Catálogo</h2>
        </div>

        <MDBRow className="product-cards">
          {products.length > 0 ? (
            products.map((product) => (
              <MDBCol
                className="mdb-col-catalogo"
                md={3}
                sm={6}
                key={product.id}
              >
                <div className="card-wrapper" onClick={() => goToProduct(product.nome)}>
                  <div className="card-frame">
                    <img
                      src={cardFrame} // Fundo do Card para cada produto
                      alt="Fundo do Card"
                      className="card-background"
                    />
                    <img
                      src={`${api}${product.thumbnail_url}` || logo} // Exibe a thumbnail se disponível, senão exibe a logo
                      alt={product.nome}
                      className="product-image"
                    />
                  </div>
                  <p className="product-name">{product.nome}</p>
                </div>
              </MDBCol>
            ))
          ) : (
            <p className="nenhum-produto">Nenhum produto disponível no momento.</p> // Mensagem exibida caso não haja produtos
          )}
        </MDBRow>
      </MDBContainer>

      {/* Gallery Section */}
      <MDBContainer className="gallery-section">
        <div className="text-wrapper">
          <h5 className="subtitulo">Galeria</h5>
          <h2 className="section-title">Galeria</h2>
        </div>
        <div className="gallery-slider">
          <div className="p-gallery-slide">
            <img src={galeria01} alt="Galeria 1" className="gallery-image" />
          </div>
          <div className="p-gallery-slide">
            <img src={galeria02} alt="Galeria 2" className="gallery-image" />
          </div>
          <div className="p-gallery-slide">
            <img src={galeria03} alt="Galeria 3" className="gallery-image" />
          </div>
          <div className="p-gallery-slide">
            <img src={galeria04} alt="Galeria 4" className="gallery-image" />
          </div>
        </div>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Products;
