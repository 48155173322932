import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/navbar';
import Footer from './Components/footer';
import Index from './Pages/index';
import About from './Pages/about';
import Products from './Pages/products'; // Página com o catálogo
import ProductPage from './Pages/product'; // Página com o produto selecionado
import Differential from './Pages/differential';
import Simulation from './Pages/simulation';
import Contact from './Pages/contact';
import FloatingWhatsAppButton from './Components/whatsappFloat'; // Caminho para o novo componente

function App() {
  
  return (
    <Router>
      <Navbar />
      <div className="App">
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/produto/:slug" element={<ProductPage />} /> {/* Rota para produto específico */}
          <Route path="/differential" element={<Differential />} />
          <Route path="/simulation" element={<Simulation />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
        <FloatingWhatsAppButton />
      </div>
    </Router>
  );
}

export default App;
