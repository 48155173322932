import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import "../Styles/whatsappFloat.css";

const FloatingWhatsAppButton = () => {
  const handleClick = () => {
    window.open('https://api.whatsapp.com/send?phone=11993891149', '_blank');
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleClick}>
      <FaWhatsapp size={40} />
    </div>
  );
};

export default FloatingWhatsAppButton;
