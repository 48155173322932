import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import logo from "../Assets/Images/logoprimepisos.png"; // Logo da Prime Pisos
import "../Styles/productPage.css"; // Importar o CSS para estilizar a página
import { api } from "../controllers/apiController";

// Importar ícones do react-icons
import { FaBoxOpen, FaInfoCircle, FaListUl, FaImage } from "react-icons/fa";

const ProductPage = () => {
  const { slug } = useParams(); // Captura o slug da URL
  const [product, setProduct] = useState(null); // Estado para armazenar o produto
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [legends, setLegends] = useState([]); // Estado para armazenar as legendas

  useEffect(() => {
    const encodedSlug = encodeURIComponent(slug);
    const url = `${api}/produto/${encodedSlug}`;
    console.log("URL da API: ", url); // Verifique a URL gerada

    axios
      .get(url)
      .then((response) => {
        const produto = response.data.produto;
        setProduct(produto); // Armazena o produto retornado no estado

        // Verificar se o produto tem legendas e parsear
        if (produto.legendas) {
          const parsedLegends = parseLegendas(produto.legendas);
          setLegends(parsedLegends);
        }

        setLoading(false); // Desativa o estado de carregamento
      })
      .catch((error) => {
        console.error("Erro ao buscar o produto:", error);
        setLoading(false); // Desativa o estado de carregamento em caso de erro
      });
  }, [slug]);

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  // Função para parsear as legendas
  const parseLegendas = (legendasText) => {
    const regex = /{([^}]+)}([^{}]*)/g;
    let match;
    const legendsArray = [];

    while ((match = regex.exec(legendasText)) !== null) {
      const title = match[1].trim();
      const content = match[2].trim();
      legendsArray.push({ title, content });
    }
    return legendsArray;
  };

  if (loading) {
    return <div>Carregando...</div>; // Mostra mensagem de carregamento
  }

  if (!product) {
    return <div>Produto não encontrado</div>; // Caso o produto não seja encontrado
  }

  // Definir os campos importantes para a tabela
  const importantFields = [
    product.dimensoes,
    product.material_placa,
    product.resistencia_carga_concentrada,
    product.carga_concentrada_seguranca,
    product.resistencia_carga_distribuida,
    product.carga_impacto,
    product.carga_rolante,
    product.carga_concentrada_pedestal,
    product.peso_por_placa,
    product.espessura_tinta,
    product.peso_conjunto,
    product.revestimentos_possiveis,
  ];

  // Verificar se algum dos campos importantes está faltando
  const isAnyImportantFieldMissing = importantFields.some(
    (field) => !field || field.trim() === ""
  );

  // Processar as imagens
  const images = product.imagens_corpo ? product.imagens_corpo.split(",") : [];

  return (
    <MDBContainer fluid className="product-page p-0">
      <div className="products-banner-section">
        <img src={logo} alt="Prime Pisos Logo" className="logo" />
      </div>
      <div className="product-wrapper">
        {/* Primeira Seção - Título */}
        <MDBRow className="align-items-center">
          <MDBCol md={12}>
            <h1 className="product-title">
              <FaBoxOpen /> {product.nome}
            </h1>
          </MDBCol>
        </MDBRow>

        {/* Renderização condicional */}
        {!isAnyImportantFieldMissing ? (
          // Quando a tabela existe
          <>
            <MDBRow className="mt-5 product-holder">
              {/* Coluna Esquerda: Descrição e Imagens */}
              <MDBCol lg={6}>
                {/* Descrição do Produto */}
                <div className="product-description">
                  <h2>
                    <FaInfoCircle /> Descrição do Produto
                  </h2>
                  <p>
                    {product.descricao
                      ? product.descricao.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                      : "Descrição não disponível."}
                  </p>
                </div>
                {/* Galeria de Imagens */}
                {images.length > 0 ? (
                  <>
                    <h2 className="mt-4">
                      <FaImage /> Galeria de Imagens
                    </h2>
                    <MDBRow className="d-flex justify-content-center mt-2">
                      {images.map((image, index) => (
                        <MDBCol
                          md={6}
                          className="mb-4 product-img-wrapper"
                          key={index}
                        >
                          <img
                            src={`${api}${image}`}
                            alt={`Produto - Imagem ${index + 1}`}
                            className="product-gallery-image"
                          />
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </>
                ) : (
                  <p>Nenhuma imagem disponível.</p>
                )}
              </MDBCol>
              {/* Coluna Direita: Tabela */}
              <MDBCol lg={6}>
                {/* Tabela de informações */}
                <h3 className="table-section-title">
                  <FaListUl /> Detalhes Técnicos
                </h3>
                <MDBTable className="product-table">
                  <MDBTableHead className="product-header">
                    <tr>
                      <th>MODELO</th>
                      <th>{product.modelo}</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    <tr>
                      <td className="product-table-text-1">Dimensões</td>
                      <td className="product-table-text">
                        {product.dimensoes}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Material da Placa
                      </td>
                      <td className="product-table-text">
                        {product.material_placa}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Resistência à Carga Concentrada
                      </td>
                      <td className="product-table-text">
                        {product.resistencia_carga_concentrada}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Carga Concentrada de Segurança
                      </td>
                      <td className="product-table-text">
                        {product.carga_concentrada_seguranca}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Resistência à Carga Distribuída
                      </td>
                      <td className="product-table-text">
                        {product.resistencia_carga_distribuida}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Carga de Impacto
                      </td>
                      <td className="product-table-text">
                        {product.carga_impacto}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">Carga Rolante</td>
                      <td className="product-table-text">
                        {product.carga_rolante}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Carga Concentrada do Pedestal
                      </td>
                      <td className="product-table-text">
                        {product.carga_concentrada_pedestal}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">Peso por Placa</td>
                      <td className="product-table-text">
                        {product.peso_por_placa}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Espessura da Tinta
                      </td>
                      <td className="product-table-text">
                        {product.espessura_tinta}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Peso do Conjunto
                      </td>
                      <td className="product-table-text">
                        {product.peso_conjunto}
                      </td>
                    </tr>
                    <tr>
                      <td className="product-table-text-1">
                        Revestimentos Possíveis
                      </td>
                      <td className="product-table-text">
                        {product.revestimentos_possiveis}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
            </MDBRow>
          </>
        ) : legends.length > 0 ? (
          // Se a tabela não existe e há legendas
          <>
            {/* Descrição do Produto no topo */}
            <MDBRow className="mt-5">
              <MDBCol lg={12}>
                <div className="product-description">
                  <h2>
                    <FaInfoCircle /> Descrição do Produto
                  </h2>
                  <p>
                    {product.descricao
                      ? product.descricao.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                      : "Descrição não disponível."}
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
            {/* Imagens e Legendas lado a lado */}
            <MDBRow className="mt-5 p-3 type-2-wrapper">
              <MDBCol lg={6}>
                {/* Legendas com bolinhas */}
                <div className="product-legends">
                  <h2>
                    <FaListUl /> Detalhes do Produto
                  </h2>
                  {legends.map((legend, index) => (
                    <div key={index} className="legend-item">
                      <h3>{legend.title}</h3>
                      <ul>
                        {legend.content.split("\n").map((line, idx) => (
                          <li key={idx}>{line}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </MDBCol>
              <MDBCol lg={6}>
                {/* Imagens do produto */}
                {images.length > 0 ? (
                  <>
                    <h2>
                      <FaImage /> Galeria de Imagens
                    </h2>
                    <MDBRow className="d-flex justify-content-center mt-2">
                      {images.map((image, index) => (
                        <MDBCol
                          md={6}
                          className="mb-4 product-img-wrapper"
                          key={index}
                        >
                          <img
                            src={`${api}${image}`}
                            alt={`Produto - Imagem ${index + 1}`}
                            className="product-gallery-image"
                          />
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </>
                ) : (
                  <p>Nenhuma imagem disponível.</p>
                )}
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          // Caso não haja tabela nem legendas, carregue as imagens na parte de baixo
          <>
            {/* Descrição do Produto no topo */}
            <MDBRow className="mt-5">
              <MDBCol lg={12}>
                <div className="product-description">
                  <h2>
                    <FaInfoCircle /> Descrição do Produto
                  </h2>
                  <p>
                    {product.descricao
                      ? product.descricao.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                      : "Descrição não disponível."}
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
            {/* Imagens na parte de baixo */}
            {images.length > 0 ? (
              <>
                <h2 className="mt-4 text-center">
                  <FaImage /> Galeria de Imagens
                </h2>
                <MDBRow className="mt-3 d-flex justify-content-center">
                  {images.map((image, index) => (
                    <MDBCol
                      md={3}
                      className="mb-4 product-img-wrapper"
                      key={index}
                    >
                      <img
                        src={`${api}${image}`}
                        alt={`Produto - Imagem ${index + 1}`}
                        className="product-gallery-image"
                      />
                    </MDBCol>
                  ))}
                </MDBRow>
              </>
            ) : (
              <p>Nenhuma imagem disponível.</p>
            )}
          </>
        )}
      </div>
    </MDBContainer>
  );
};

export default ProductPage;
