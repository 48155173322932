import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../Styles/navbar.css";
import Logo from "../Assets/Images/logoprimepisos.png"; // Importando a logo
import { AiOutlineAppstore } from "react-icons/ai";
import { FaHome, FaInfoCircle, FaBoxOpen, FaCogs, FaCalculator } from "react-icons/fa"; // Ícones adicionados

export default function Navbar() {
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Função para alternar a barra lateral
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Botão do menu para dispositivos móveis */}
      <div className="menu-btn" onClick={toggleSidebar}>
        <AiOutlineAppstore />
      </div>

      {/* Navbar lateral */}
      <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
        <div className="sidebar-header">
          <div className="close-btn" onClick={toggleSidebar}>×</div>
          <img src={Logo} alt="Prime Pisos Logo" className="sidebar-logo" />
        </div>
        <ul className="sidebar-nav">
          <li>
            <NavLink
              to="/"
              className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
              onClick={toggleSidebar}
            >
              <FaHome className="nav-icon" /> Início
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={`nav-link ${location.pathname === "/about" ? "active" : ""}`}
              onClick={toggleSidebar}
            >
              <FaInfoCircle className="nav-icon" /> Sobre Nós
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/products"
              className={`nav-link ${location.pathname === "/products" ? "active" : ""}`}
              onClick={toggleSidebar}
            >
              <FaBoxOpen className="nav-icon" /> Produtos
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/differential"
              className={`nav-link ${location.pathname === "/differential" ? "active" : ""}`}
              onClick={toggleSidebar}
            >
              <FaCogs className="nav-icon" /> Diferenciais
            </NavLink>
          </li>
          <li className="sim-link-wrapper">
            <NavLink
              to="/simulation"
              className={`sim-link nav-link ${location.pathname === "/simulation" ? "active" : ""}`}
            >
              <FaCalculator className="nav-icon" /> Simulação
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Navbar normal para dispositivos maiores */}
      <nav className="navbar">
        <div className="navbar-container">
          <ul className="navbar-nav">
            <li>
              <NavLink
                to="/"
                className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
              >
                <FaHome className="nav-icon" /> Início
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={`nav-link ${location.pathname === "/about" ? "active" : ""}`}
              >
                <FaInfoCircle className="nav-icon" /> Sobre Nós
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                className={`nav-link ${location.pathname === "/products" ? "active" : ""}`}
              >
                <FaBoxOpen className="nav-icon" /> Produtos
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/differential"
                className={`nav-link ${location.pathname === "/differential" ? "active" : ""}`}
              >
                <FaCogs className="nav-icon" /> Diferenciais
              </NavLink>
            </li>
            <li className="sim-link-wrapper">
              <NavLink
                to="/simulation"
                className={`sim-link nav-link ${location.pathname === "/simulation" ? "active" : ""}`}
              >
                <FaCalculator className="nav-icon" /> Simulação
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
