import React from "react";
import { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FaCogs, FaPaintRoller, FaCheckCircle, FaStar, FaBolt } from "react-icons/fa"; // Ícones adicionais
import "../Styles/differential.css"; // Importar o CSS atualizado
import logo from "../Assets/Images/logoprimepisos.png"; // Logo da Prime Pisos
import beneficiosImage1 from "../Assets/Images/Beneficios/beneficio1.png"; // Imagens para os cards de benefícios
import beneficiosImage2 from "../Assets/Images/Beneficios/beneficio2.png";
import beneficiosImage3 from "../Assets/Images/Beneficios/beneficio3.png";
import beneficiosImage4 from "../Assets/Images/Beneficios/beneficio4.png";

const Differential = () => {

  useEffect(() => {
    // Ao carregar a página, força o scroll para o topo
    window.scrollTo(0, 0);
  }, []); // O array vazio faz com que o efeito seja executado apenas uma vez


  return (
    <MDBContainer fluid className="differential-page p-0">
      {/* Banner Section */}
      <div className="diff-banner-section">
        <img src={logo} alt="Prime Pisos Logo" className="logo animate__animated animate__fadeInDown" />
      </div>

      {/* Tecnologia Section */}
      <MDBContainer className="technology-section">
        <div className="text-wrapper">
          <h5 className="subtitulo">Tecnologia <FaBolt className="icon-subtitle" /></h5>
          <h2 className="section-title">Tecnologia de Ponta</h2>
        </div>

        <MDBRow className="technology-cards">
          <MDBCol md="3" className="card-spaces">
            <div className="technology-card animate__animated animate__fadeInUp">
              <FaCogs className="icon" /> {/* Ícone de engrenagens */}
              <div className="technology-content">
                <h3 className="card-title">Materiais de Alta Qualidade</h3>
                <p className="card-text">
                  Pisos elevados em aço carbono, preenchido com argamassa leve.
                </p>
              </div>
            </div>
          </MDBCol>

          <MDBCol md="3" className="card-spaces">
            <div className="technology-card animate__animated animate__fadeInUp">
              <FaPaintRoller className="icon" /> {/* Ícone de rolo de pintura */}
              <div className="technology-content">
                <h3 className="card-title">Revestimentos Avançados</h3>
                <p className="card-text">
                  Pintura eletrostática à base de epóxi para alta resistência e
                  finalização.
                </p>
              </div>
            </div>
          </MDBCol>

          <MDBCol md="3" className="card-spaces">
            <div className="technology-card animate__animated animate__fadeInUp">
              <FaCheckCircle className="icon" /> {/* Ícone de aprovação/checagem */}
              <div className="technology-content">
                <h3 className="card-title">Testes de Qualidade</h3>
                <p className="card-text">
                  Produtos testados conforme normas NBR para máxima segurança.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Benefícios Section */}
      <MDBContainer className="benefits-section">
        <div className="text-wrapper">
          <h5 className="subtitulo">Benefícios <FaStar className="icon-subtitle" /></h5>
          <h2 className="section-title">Vantagens dos Nossos Produtos</h2>
        </div>

        <MDBRow className="benefits-cards">
          <MDBCol md="5" className="card-spaces">
            <div className="benefits-card animate__animated animate__fadeInLeft">
              <img
                src={beneficiosImage1}
                alt="Benefício 1"
                className="benefits-image"
              />
              <div className="benefits-content">
                <h3 className="card-title">Resistência e Durabilidade</h3>
                <p className="card-text">
                  Nossos pisos são fabricados com materiais de alta qualidade.
                </p>
              </div>
            </div>
          </MDBCol>

          <MDBCol md="5" className="card-spaces">
            <div className="benefits-card animate__animated animate__fadeInRight">
              <img
                src={beneficiosImage4}
                alt="Benefício 4"
                className="benefits-image"
              />
              <div className="benefits-content">
                <h3 className="card-title">Soluções Personalizadas</h3>
                <p className="card-text">
                  Adaptamos nossos pisos elevados às necessidades dos clientes.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="benefits-cards">
          <MDBCol md="5" className="card-spaces">
            <div className="benefits-card animate__animated animate__fadeInLeft">
              <img
                src={beneficiosImage2}
                alt="Benefício 2"
                className="benefits-image"
              />
              <div className="benefits-content">
                <h3 className="card-title">Manutenção Fácil</h3>
                <p className="card-text">
                  Projetados para facilitar a instalação e a manutenção.
                </p>
              </div>
            </div>
          </MDBCol>

          <MDBCol md="5" className="card-spaces">
            <div className="benefits-card animate__animated animate__fadeInRight">
              <img
                src={beneficiosImage3}
                alt="Benefício 3"
                className="benefits-image"
              />
              <div className="benefits-content">
                <h3 className="card-title">Versatilidade de Aplicação</h3>
                <p className="card-text">
                  Ideais para diferentes tipos de ambientes comerciais.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Differential;
