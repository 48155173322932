import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaInstagram, FaTwitter, FaFacebook, FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import Logo from "../Assets/Images/logoprimepisos.png";
import "../Styles/footer.css";

export default function Footer() {
  return (
    <MDBFooter id="footer" className="footer text-center text-white">

      <MDBContainer id="footer-container" className="footer-container d-flex justify-content-center">

        <MDBRow id="footer-itens" className="w-100 d-flex justify-content-center">

          <MDBCol lg="4" id="footer-left" className="d-flex flex-column align-items-center">
            <div className="footer-logo mb-3">
              <img src={Logo} alt="Prime Pisos Elevados" />
            </div>
            <div className="footer-info text-center">

              <p>
                Prime Pisos, mais qualidade por metro quadrado. Sua satisfação é a nossa meta.
              </p>
            </div>
          </MDBCol>

          <MDBCol lg="4" id="footer-center" className="d-flex flex-column align-items-start text-start">
            <div className="footer-contact">
              <h3 className="secondary-color">Contatos</h3>
              <p>
                <a style={{ color: '#f5f5f5' }} href="tel:1127073738">
                  <FaPhoneAlt /> +55 11 2707-3738
                </a>
              </p>
              <p>
                <a style={{ color: '#f5f5f5' }} href="https://api.whatsapp.com/send?phone=11993891149">
                  <IoLogoWhatsapp color="white" /> (11) 9 9389-1149
                </a>
              </p>
              <p>
                <a style={{ color: '#f5f5f5' }} href="https://api.whatsapp.com/send?phone=11994464218">
                  <IoLogoWhatsapp color="white" />(11) 9 9446-4218
                </a>
              </p>
              <p>
                <a style={{ color: '#f5f5f5' }} href='mailto:atendimento@primepisoselevados.com.br'>
                  <span style={{ display: 'inline-block' }}><FaEnvelope /></span> <span style={{ display: 'inline-flex', width: '80%', fontSize: '0.8rem' }}>atendimento@primepisoselevados.com.br</span>
                </a>
              </p>
              <p>
                <a style={{ color: '#f5f5f5' }} href='mailto:primepisoselevados@primepisoselevados.com.br'>
                  <span style={{ display: 'inline-block' }}><FaEnvelope /></span> <span style={{ display: 'inline-flex', width: '80%', fontSize: '0.75rem' }}>primepisoselevados@primepisoselevados.com.br</span>
                </a>
              </p>
              <p>
                <a style={{ color: '#f5f5f5' }} href="https://www.youtube.com/@PrimePisosElevados">
                  <FaYoutube color="red" /> @PrimePisosElevados
                </a>
              </p>
            </div>
            <div className="footer-address">
              <h3 className="secondary-color">Venha nos visitar</h3>
              <p>
                <FaMapMarkerAlt /> Rua Dr. Edgard Magalhães Noronha, n° 355 - Vila Nova York, São Paulo - SP, 03480-000
              </p>
            </div>
          </MDBCol>

          <MDBCol md="12" id="footer-right" className="mt-5 d-flex align-items-center justify-items-center">
            <div className="d-flex align-items-center mt-5" style={{ flexDirection: 'row', gap: '2rem' }}>
              <a href="https://www.instagram.com/" className="social-icon mb-3" style={{ display: "inline-flex" }}>
                <FaInstagram />
              </a>
              <a href="https://www.twitter.com/" className="social-icon mb-3" style={{ display: "inline-flex" }}>
                <FaTwitter />
              </a>
              <a href="https://www.facebook.com/" className="social-icon" style={{ display: "inline-flex" }}>
                <FaFacebook />
              </a>
            </div>
          </MDBCol>

        </MDBRow>

      </MDBContainer>

      <div className="footer-bottom">
        <p>© 2024 - Prime Pisos Elevados Ltda.</p>
      </div>

    </MDBFooter>
  );
}
